import * as React from "react";
import styled from "styled-components";
import theme from "../../assets/css/theme";
import {countBy, get, isNumber, last} from "lodash";

import {
	Exist,
	IconArrowSlim,
	IconWarning,
	RegularButton,
} from "../../components";
import {IGameWeek} from "../../modules/reducers";
import {ISnapshot} from "../../modules/reducers";
// import ShareIcon from '../../assets/img/icons/Share.svg';
import {IUserTips} from "../../modules/types";
import {below} from "../../assets/css/media";
import ShareButton from "../../components/Buttons/ShareButton";

import IconLock from "../../assets/img/icons/lock.svg";
import IconInfo from "../../assets/img/icons/Info.svg";

import au_ad_banner from "assets/img/ad_banners/au_ad_banner.jpg";
import br_ad_banner from "assets/img/ad_banners/br_ad_banner.jpg";
import de_ad_banner from "assets/img/ad_banners/de_ad_banner.jpg";
import fr_ad_banner from "assets/img/ad_banners/fr_ad_banner.jpg";
import mx_ad_banner from "assets/img/ad_banners/mx_ad_banner.jpg";
import uk_ad_banner from "assets/img/ad_banners/uk_ad_banner.jpg";
import {Fragment} from "react";

const SidebarWrapper = styled.div`
	background: ${theme.primary ?? "#143cab"};
`;

const DataWrapper = styled.div`
	display: flex;
	height: 80px;
	align-items: center;
	max-width: ${theme.content_width};
	margin: 0 auto;
	justify-content: space-between;
`;

const ButtonStyled = styled(RegularButton)`
	min-width: 100px;
	margin-left: 10px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${below.desktop`
		height: 32px;
		width: auto;
		font-size: 10px;
	`};

	&:disabled {
		opacity: 0.3;
	}
`;

const InfoButton = styled(RegularButton)`
	min-width: 40px;
	margin-left: 10px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	${below.desktop`
		height: 32px;
		width: 32px;
		font-size: 10px;
	`};
	@media (max-width: 400px) {
		margin-left: 0;
	}
`;

const WeekSwitch = styled.div`
	display: flex;
	flex: 15%;
	height: 100%;
	align-items: center;
	color: #ffffff;
	text-align: center;
	font-size: 14px;
`;

interface IArrowSlimButton {
	readonly disabled?: boolean;
}

const ArrowSlimButton = styled.div`
	border: none;
	cursor: pointer;
	opacity: ${({disabled}: IArrowSlimButton) => (disabled ? ".5" : "1")};
`;

const DisplayCurrentGameWeek = styled.div`
	font-family: ${theme.base_bold_font};
	margin: 0 10px;
	font-size: 14px;
	text-transform: uppercase;
`;

const PointsInfo = styled.div`
	display: flex;
	flex: 50%;
	justify-content: space-between;
	align-items: center;

	padding-right: 100px;

	${below.desktop`
		padding: 0 10px;
	`};
`;

const InfoItem = styled.div``;

const InfoTitle = styled.div`
	font-size: 9px;
	color: #ffffff;
	font-family: ${theme.base_font};
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;

	${below.desktop`
		max-width: 54px;
		height: 20px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	`};
`;

const InfoValue = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: #ffffff;
	font-family: ${theme.base_font_medium_display};
	text-align: center;
	margin-top: 5px;

	${below.desktop`
		font-size: 16px;
	`} svg, img {
		margin-left: 5px;
	}

	img {
		width: 10px;
		height: 12px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex: 30%;
	justify-content: center;
	align-items: center;

	&.standard-mobile-flex {
		margin-left: 10px;
	}

	button {
		background-color: ${theme.home_action_button_bg ?? theme.primary};
	}
`;

const AdsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;

	@media screen and (max-width: 850px) {
		a {
			width: 90%;

			img {
				width: 100%;
			}
		}
	}
`;

//
// const ShareButton = styled.div`
// 	display: block;
// 	cursor: pointer;
// 	height: 40px;
// 	width: 40px;
// 	background: url(${ShareIcon}) no-repeat center center #1B48E0;
// 	border-radius: 4px;
// `;

const MobileControlWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${theme.primary ?? "#333333"};
	height: 65px;
	padding: 0 10px;

	@media (max-width: 400px) {
		height: 90px;
	}
`;

const SmallMobileDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0 0 5px 0;
`;

const WIDTH_ARROW = 12;

const getTotalPointsValue = (isSeasonStarted: boolean, points: number) => {
	if (!isSeasonStarted || !isNumber(points)) {
		return "-";
	}

	return points;
};

interface IProps {
	onClickInfo: () => void;
	onSave: () => void;
	onAutopick: () => void;
	changeGameWeek: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => void;
	readonly actual_gameweek: IGameWeek;
	readonly current_gameweek: IGameWeek;
	readonly gameweeks: IGameWeek[];
	readonly snapshot: ISnapshot;
	readonly tips: IUserTips[];
	readonly is_selected_tips: boolean;
	readonly tips_saved: boolean;
	readonly first_round: IGameWeek;
	readonly is_logged_in: boolean;
	readonly is_season_started: boolean;
}

export class Sidebar extends React.Component<IProps> {
	get week_switch() {
		const {
			changeGameWeek,
			actual_gameweek: {id: gw_id},
			gameweeks,
			current_gameweek: {id: current_gameweek_id},
			first_round,
			is_logged_in,
		} = this.props;

		const MAX_GAME_WEEK_ID = current_gameweek_id + 1;
		const last_gw_id = get(last(gameweeks), "id", MAX_GAME_WEEK_ID);
		const prev_gw = gw_id - 1;
		const prev_arrown_loced = prev_gw === first_round.id - 1;

		const next_gw = gw_id + 1;
		const next_arrown_loced =
			next_gw > MAX_GAME_WEEK_ID || last_gw_id < next_gw;

		return (
			<React.Fragment>
				<Exist when={is_logged_in}>
					<ArrowSlimButton
						data-gw_id={prev_gw}
						onClick={
							!prev_arrown_loced ? changeGameWeek : undefined
						}
						disabled={prev_arrown_loced}
					>
						<IconArrowSlim
							color="#ffffff"
							to="left"
							width={WIDTH_ARROW}
						/>
					</ArrowSlimButton>
				</Exist>

				<DisplayCurrentGameWeek>
					{window.getTranslations(
						"competitionjoin_list_placeholder_week"
					)}{" "}
					{gw_id}
				</DisplayCurrentGameWeek>
				<Exist when={is_logged_in}>
					<ArrowSlimButton
						data-gw_id={next_gw}
						onClick={
							!next_arrown_loced ? changeGameWeek : undefined
						}
						disabled={next_arrown_loced}
					>
						<IconArrowSlim
							color="#ffffff"
							to="right"
							width={WIDTH_ARROW}
						/>
					</ArrowSlimButton>
				</Exist>
			</React.Fragment>
		);
	}

	get points_info() {
		const {snapshot, actual_gameweek, is_season_started} = this.props;
		const ICON_WIDTH = 12;
		const ICON_HEIGHT = 14;

		if (!snapshot) {
			return null;
		}

		return (
			<PointsInfo>
				<InfoItem>
					<InfoTitle>
						{window.getTranslations("pickem_gamebar_text_selected")}
					</InfoTitle>
					<InfoValue>
						{this.selected_quantity_tips}/{this.quantity_tips}
						{this.selected_quantity_tips === this.quantity_tips ? (
							<img src={IconLock} alt="lock" />
						) : (
							<IconWarning
								color="#ffffff"
								width={ICON_WIDTH}
								height={ICON_HEIGHT}
							/>
						)}
					</InfoValue>
				</InfoItem>
				<InfoItem>
					<InfoTitle>
						{window.getTranslations(
							"competitionjoin_list_placeholder_week"
						)}{" "}
						{actual_gameweek.id}{" "}
						{window.getTranslations(
							"pickem_matchup_subheading_pts"
						)}
					</InfoTitle>
					<InfoValue>
						{getTotalPointsValue(
							is_season_started,
							this.tips_points
						)}
					</InfoValue>
				</InfoItem>
				<InfoItem>
					<InfoTitle>
						{window.getTranslations("mycomps_list_total_points")}
					</InfoTitle>
					<InfoValue>
						{actual_gameweek.status === "scheduled"
							? "-"
							: snapshot.user_correct_total}
					</InfoValue>
				</InfoItem>
				<InfoItem>
					<InfoTitle>
						{window.getTranslations(
							"pickem_gambar_text_tiebreaker"
						)}
					</InfoTitle>
					<InfoValue>{snapshot.total_margin}</InfoValue>
				</InfoItem>
				<InfoItem>
					<InfoTitle>
						{window.getTranslations("mycomps_list_overall_ranking")}
					</InfoTitle>
					<InfoValue>{snapshot.overall_rank}</InfoValue>
				</InfoItem>
			</PointsInfo>
		);
	}

	get tips_points() {
		const {tips} = this.props;
		const points = countBy(tips, (item) => item.is_correct === 1);

		return points.true || 0;
	}

	get quantity_tips() {
		const {tips} = this.props;

		return tips.length;
	}

	get selected_quantity_tips() {
		const {tips} = this.props;
		const quantity_tips = countBy(
			tips,
			(item) => item.predict_winner_squad_id !== 0
		);

		return quantity_tips.true || 0;
	}

	get isTitpsSaved() {
		const {tips_saved, is_logged_in} = this.props;

		return tips_saved || is_logged_in;
	}

	private get isAutoPickDisabled() {
		if (!this.props.is_logged_in) {
			return true;
		}

		return this.selected_quantity_tips !== this.quantity_tips;
	}

	private get nav_button_small_mob() {
		const {
			onSave,
			onAutopick,
			onClickInfo,
			tips_saved,
			is_selected_tips,
			// is_logged_in,
		} = this.props;
		const is_disabled = tips_saved || !is_selected_tips;

		const save_btn_label = tips_saved
			? window.getTranslations("pickem_gamebar_button_saved")
			: window.getTranslations("pickem_gamebar_button_save");

		return (
			<React.Fragment>
				<SmallMobileDiv>
					<NavShareButton isDisabled={this.isAutoPickDisabled} />
					<NavAutoPick
						fontSize={window.LANG_KEY === "de" ? "10px" : "14px"}
						label={
							window.getTranslations(
								"pickem_gamebar_button_autpick"
							) || ""
						}
						onClick={onAutopick}
						// disabled={!is_logged_in}
					/>
				</SmallMobileDiv>
				<SmallMobileDiv>
					<NavInfoButton
						onClick={onClickInfo}
						fontSize={window.LANG_KEY === "de" ? "10px" : "14px"}
					/>
					<NavSavePick
						isDisabled={is_disabled}
						label={save_btn_label || ""}
						onClick={onSave}
					/>
				</SmallMobileDiv>
			</React.Fragment>
		);
	}

	private get nav_button() {
		const {
			onSave,
			onAutopick,
			onClickInfo,
			tips_saved,
			is_selected_tips,
			// is_logged_in,
		} = this.props;
		const is_disabled = this.isTitpsSaved || !is_selected_tips;
		return (
			<React.Fragment>
				<NavShareButton isDisabled={this.isAutoPickDisabled} />
				<NavInfoButton
					onClick={onClickInfo}
					fontSize={window.LANG_KEY === "de" ? "10px" : "14px"}
				/>
				<NavAutoPick
					fontSize={window.LANG_KEY === "de" ? "10px" : "14px"}
					label={
						window.getTranslations(
							"pickem_gamebar_button_autpick"
						) || ""
					}
					onClick={onAutopick}
					// disabled={!is_logged_in}
				/>
				<NavSavePick
					isDisabled={is_disabled}
					label={
						tips_saved
							? window.getTranslations(
									"pickem_gamebar_button_saved"
							  ) || ""
							: window.getTranslations(
									"pickem_gamebar_button_save"
							  ) || ""
					}
					onClick={onSave}
				/>
			</React.Fragment>
		);
	}

	private get mobile_control() {
		return (
			<MobileControlWrapper className="hidden-desktop">
				<WeekSwitch>{this.week_switch}</WeekSwitch>
				<ButtonWrapper className="standard-mobile-flex">
					{this.nav_button}
				</ButtonWrapper>
				<ButtonWrapper className="small-mobile">
					{this.nav_button_small_mob}
				</ButtonWrapper>
			</MobileControlWrapper>
		);
	}

	private get adBanners() {
		const current_lang = process.env.REACT_APP_AD_KEY || "";

		const adBlock =
			{
				au: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402756311;dc_trk_aid=594685899;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={au_ad_banner} alt="Ad" />
					</a>
				),
				br: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402601579;dc_trk_aid=594685866;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={br_ad_banner} alt="Ad" />
					</a>
				),
				de: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402601453;dc_trk_aid=594685800;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={de_ad_banner} alt="Ad" />
					</a>
				),
				fr: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402756377;dc_trk_aid=594685965;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={fr_ad_banner} alt="Ad" />
					</a>
				),
				mx: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402601513;dc_trk_aid=594685833;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={mx_ad_banner} alt="Ad" />
					</a>
				),
				uk: (
					<a
						href="https://ad.doubleclick.net/ddm/trackclk/N927339.4132995NFLNETWORK/B32326361.402601411;dc_trk_aid=594685767;dc_trk_cid=219534647;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={uk_ad_banner} alt="Ad" />
					</a>
				),
			}[current_lang] || null;

		return adBlock ? <AdsWrapper>{adBlock}</AdsWrapper> : null;
	}

	/**
	 * @ignore
	 */
	public render() {
		// const {actual_gameweek, current_gameweek} = this.props;

		return (
			<Fragment>
				<SidebarWrapper>
					<DataWrapper>
						<WeekSwitch className="hidden-mobile">
							{this.week_switch}
						</WeekSwitch>
						{this.points_info}
						<ButtonWrapper className="hidden-mobile">
							{this.nav_button}
						</ButtonWrapper>
					</DataWrapper>

					{this.mobile_control}
				</SidebarWrapper>
				{this.adBanners}
			</Fragment>
		);
	}
}

function NavShareButton({isDisabled}: {isDisabled: boolean}) {
	return (
		<ShareButton
			aria-label="share"
			gamebar={true}
			disabled={isDisabled}
			section="general"
		/>
	);
}

function NavInfoButton({
	fontSize,
	onClick,
}: {
	fontSize: string;
	onClick: () => void;
}) {
	return (
		<InfoButton
			aria-label="information"
			onClick={onClick}
			font_size={fontSize}
		>
			<img alt="information" src={IconInfo} />
		</InfoButton>
	);
}

function NavAutoPick({
	fontSize,
	label,
	onClick,
	disabled,
}: {
	fontSize: string;
	label: string;
	onClick: () => void;
	disabled?: boolean;
}) {
	return (
		<ButtonStyled
			title={label}
			aria-label={label}
			onClick={onClick}
			font_size={fontSize}
			disabled={disabled}
		>
			{label}
		</ButtonStyled>
	);
}

function NavSavePick({
	isDisabled,
	label,
	onClick,
}: {
	isDisabled: boolean;
	label: string;
	onClick: () => void;
}) {
	return (
		<ButtonStyled
			aria-label={label}
			onClick={onClick}
			disabled={isDisabled}
		>
			{label}
		</ButtonStyled>
	);
}

export default Sidebar;
